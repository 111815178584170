export function generateColorScale(hexColor: string): Record<50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900, string> {
  const clamp = (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max);
  };

  const hexToRgb = (hex: string): [number, number, number] => {
    hex = hex.startsWith('#') ? hex.slice(1) : hex;
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }
    if (!/^([0-9A-Fa-f]{6})$/.test(hex)) {
      throw new Error('Invalid hex color format');
    }
    return [parseInt(hex.substring(0, 2), 16), parseInt(hex.substring(2, 4), 16), parseInt(hex.substring(4, 6), 16)];
  };

  const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (value: number): string => Math.round(value).toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const adjustColor = (rgb: [number, number, number], factor: number): string => {
    return rgbToHex(
      clamp(rgb[0] + (255 - rgb[0]) * factor, 0, 255),
      clamp(rgb[1] + (255 - rgb[1]) * factor, 0, 255),
      clamp(rgb[2] + (255 - rgb[2]) * factor, 0, 255),
    );
  };

  const darkenColor = (rgb: [number, number, number], factor: number): string => {
    return rgbToHex(clamp(rgb[0] * (1 - factor), 0, 255), clamp(rgb[1] * (1 - factor), 0, 255), clamp(rgb[2] * (1 - factor), 0, 255));
  };

  const baseRgb = hexToRgb(hexColor);

  return {
    50: adjustColor(baseRgb, 0.9),
    100: adjustColor(baseRgb, 0.75),
    200: adjustColor(baseRgb, 0.5),
    300: adjustColor(baseRgb, 0.3),
    400: adjustColor(baseRgb, 0.15),
    500: hexColor,
    600: darkenColor(baseRgb, 0.1),
    700: darkenColor(baseRgb, 0.2),
    800: darkenColor(baseRgb, 0.3),
    900: darkenColor(baseRgb, 0.4),
  };
}
