import { CreateUserFormValues } from '@client-ui/components/forms/create-user-form/utils/form-utils';
import { ErrorModel } from '@shared/models/app-state.model';

export interface SignUpUserState {
  entity: CreateUserFormValues | null;
  loading: boolean;
  errors: ErrorModel | null;
}

export const signUpUserSelectorName = 'signUpUser';
