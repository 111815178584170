import { HTTP_CP_ListSignInCompanies_Response_Company, HTTP_CP_SignIn_WithCode_Response } from '@api-new/authservice';
import { HTTP_CP_LoadSignInBranding_Response } from '@api-new/companyservice';
import { HTTP_CP_DoesEmailExist_Response } from '@api-new/userservice';
import { createAction, props } from '@ngrx/store';
import { ApiErrorCode } from '../../enums/api-error-code.enum';
import { ErrorModel } from '../../models/app-state.model';

export const getListOfSignInCompanies = createAction(
  '[AUTH] Get List Of Sign In Companies',
  props<{
    verificationCode: string;
  }>(),
);

export const getListOfSignInCompaniesSuccess = createAction(
  '[AUTH] Get List Of Sign In Companies Success',
  props<{
    companies: HTTP_CP_ListSignInCompanies_Response_Company[];
  }>(),
);

export const getListOfSignInCompaniesFailure = createAction(
  '[AUTH] Get List Of Sign In Companies Failure',
  props<{
    error: ErrorModel;
  }>(),
);

export const login = createAction('[AUTH] Login', props<{ companyId: string }>());

export const loginSuccess = createAction(
  '[AUTH] Login Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
    link?: string;
  }>(),
);

export const loginFailure = createAction('[AUTH] Login error', props<{ error: ErrorModel }>());

export const loginMagicLink = createAction(
  '[AUTH] Login Via Magic Link',
  props<{ email: string; verificationCode: string; redirectUrl?: string }>(),
);

export const loginMagicLinkSuccess = createAction(
  '[AUTH] Login Via Magic Link Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
    link?: string;
  }>(),
);

export const loginMagicLinkFailure = createAction(
  '[AUTH] Login Via Magic Link error',
  props<{ error: ErrorModel; email: string; code: string }>(),
);

export const loginWithToken = createAction('[AUTH] Login With Token', props<{ token: string; redirectUrl?: string }>());
export const loginWithTokenSuccess = createAction(
  '[AUTH] Login Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
  }>(),
);

export const clearLastSignInCode = createAction('[AUTH] Clear Last Sign In Code');

export const passwordReset = createAction('[AUTH] Password Reset', props<{ email: string }>());

export const passwordResetSuccess = createAction('[AUTH] Password Reset Success');

export const passwordResetFailure = createAction('[AUTH] Password Reset Failure', props<{ error: ErrorModel }>());

export const resetPasswordResetDone = createAction('[AUTH] Reset Password Reset Done');

export const logoutAndRedirect = createAction('[AUTH] Logout And Redirect');

export const resetStore = createAction('[AUTH] Reset Store');

export const authError = createAction('[AUTH] Auth Error', props<{ authErrorCode: ApiErrorCode }>());

export const getAuthPagesBranding = createAction('[AUTH] Get Auth Pages Branding', props<{ domain: string }>());

export const getAuthPagesBrandingSuccess = createAction(
  '[AUTH] Get Auth Pages Branding Success',
  props<{
    branding: HTTP_CP_LoadSignInBranding_Response;
  }>(),
);

export const getAuthPagesBrandingFailure = createAction('[AUTH] Get Auth Pages Branding Failure', props<{ error: ErrorModel }>());

export const sendVerificationCode = createAction('[AUTH] Send Verification Code Mail', props<{ email: string }>());

export const sendVerificationCodeSuccess = createAction('[AUTH] Send Verification Code Mail success');

export const sendVerificationCodeFailure = createAction('[AUTH] Send Verification Code Mail Failure', props<{ error: ErrorModel }>());

export const checkLoginMail = createAction('[AUTH] Check User Mail Before Login', props<{ email: string }>());

export const checkLoginMailSuccess = createAction('[AUTH] Check User Mail Before Login Success', props<HTTP_CP_DoesEmailExist_Response>());

export const checkLoginMailFailure = createAction('[AUTH] Check User Mail Before Login Failure', props<{ error: ErrorModel }>());

export const agreeToTermsAndConditions = createAction('[AUTH] Agree To Terms And Conditions');

export const agreeToTermsAndConditionsSuccess = createAction('[AUTH] Agree To Terms And Conditions Success');

export const agreeToTermsAndConditionsFailure = createAction(
  '[AUTH] Agree To Terms And Conditions Failure',
  props<{
    error: ErrorModel;
  }>(),
);

export const askForTermsAndConditions = createAction('[AUTH] Ask For Terms And Conditions');

export const navigateToVerifyCodeAndSendEmail = createAction('[AUTH] Navigate To Verify Code And Send Email');

export const navigateToVerifyCode = createAction('[AUTH] Navigate To Verify Code');

export const setRedirectUrlAfterLoginAction = createAction('[AUTH] Set Redirect Link After Login', props<{ redirectUrl: string }>());
