import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AuthService } from '@shared/services/auth.service';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class DashlyErrorInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  private readonly MAX_RETRIES = 4;
  private readonly RETRY_DELAY = 2000;

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      retry({
        count: this.MAX_RETRIES,
        delay: (error: unknown) => {
          if (error instanceof HttpErrorResponse && error.status === 0) {
            return timer(this.RETRY_DELAY);
          }
          throw error;
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          Sentry.captureException(error.error?.message ?? error.message, {
            fingerprint: error.error?.message ? [error.error.message] : [error.url],
            extra: { error, clientId: this.authService.userIdFromToken(), request: req, requestBody: JSON.stringify(req.body) },
          });
        }

        if (
          error.status === 401 &&
          (error.error.message.includes('ACCESS_TOKEN__EXPIRED') ||
            error.error.message.includes('ACCESS_TOKEN__INVALID_APPLICATION') ||
            error.error.message.includes('ACCESS_TOKEN__INVALID_TOKEN'))
        ) {
          this.authService.logoutAndRedirect();
        }

        return throwError(() => error);
      }),
    );
  }
}
