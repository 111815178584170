import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'shared-otp-input',
  imports: [CommonModule, FormsModule],
  templateUrl: './otp-input.component.html',
  styleUrl: './otp-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpInputComponent implements OnInit {
  @ViewChildren('optInput') inp!: QueryList<ElementRef>;
  @Output() allInputsFiledChange = new EventEmitter<string[]>();
  @Input() size = 6;
  result: string[] = [];

  ngOnInit(): void {
    this.result = new Array(this.size).fill(null);
  }

  onKeyUp(event: Event, index: number): void {
    const el = event.target as HTMLInputElement;
    if (!el.value) {
      const prev = this.inp.get(index - 1) || this.inp.get(0);
      prev?.nativeElement.focus();
      return;
    }

    if (el.value) {
      const value = el.value.split('');
      const last = Math.min(this.result.length, index + value.length);
      for (let curr = index; curr < last; curr++) {
        this.result[curr] = value[curr - index];
      }
      this.inp.get(last)?.nativeElement.focus();

      this.emitChange();
      return;
    }
  }

  emitChange(): void {
    // check if all are filled
    if (this.result.findIndex((d) => d === null || d === undefined || d === '') !== -1) return;
    this.allInputsFiledChange.emit(this.result);
  }
}
