import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AbstractControlToFormControlPipe } from '../../../pipes/abstract-control-to-form-control.pipe';

@Component({
  selector: 'shared-input-text',
  imports: [ReactiveFormsModule, AbstractControlToFormControlPipe, InputTextModule],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputTextComponent {
  @Input() name: string | undefined;
  @Input() placeholder: string | null = '';
  @Input() autocomplete: 'on' | 'off' = 'on';

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // trick it into thinking control has a valueAccessor, we don't need it because child has
      this.ngControl.valueAccessor = {
        writeValue(): void {
          // do nothing
        },
        registerOnChange(): void {
          // do nothing
        },
        registerOnTouched(): void {
          // do nothing
        },
      };
    }
  }
}
