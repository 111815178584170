import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { createReducer, on } from '@ngrx/store';
import {
  loadSignUpFlowFromStorageSuccess,
  setSignUpFlowCurrentStep,
  setSignUpFlowData,
  setSignUpFlowInitialStep,
} from '@shared/store/sign-up/flow/flow.actions';
import { SignUpFlowState } from './flow.state';

export const signUpFlowInitialState: SignUpFlowState = {
  entity: { landing: null, creationMethod: null },
  initialStep: ENTRY_FLOW.Landing,
  currentStep: ENTRY_FLOW.Landing,
  errors: null,
};

export const signUpFlowReducer = createReducer(
  signUpFlowInitialState,
  on(setSignUpFlowCurrentStep, (state, { step }) => ({
    ...state,
    currentStep: step,
  })),

  on(setSignUpFlowInitialStep, (state, { step }) => ({
    ...state,
    initialStep: step,
  })),

  on(setSignUpFlowData, (state, entity) => ({
    ...state,
    entity: {
      landing: entity.landing,
      creationMethod: entity.creationMethod,
    },
  })),

  on(loadSignUpFlowFromStorageSuccess, (_, { state }) => ({ ...(state ?? signUpFlowInitialState) })),
);
