import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { AppState } from '@shared/models/app-state.model';
import { resetStore } from '@shared/store/auth/auth.action';
import { clearClientUser } from '@shared/store/client-user/client-user.actions';
import moment from 'moment';

const IMPERSONATED = 'impersonated';
const ACCESS_TOKEN = 'accessToken';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly store = inject(Store<AppState>);
  private readonly router = inject(Router);

  setImpersonated(): void {
    window.sessionStorage.setItem(IMPERSONATED, 'true');
  }

  get isImpersonated(): boolean {
    return window.sessionStorage.getItem(IMPERSONATED) === 'true';
  }

  token(): string {
    if (this.isImpersonated) {
      return window.sessionStorage.getItem(ACCESS_TOKEN);
    }

    return window.localStorage.getItem(ACCESS_TOKEN);
  }

  /**
   * loginWithCustomToken logs in the user with a custom provided token. This method is used
   * @param token is a token generated by the backend
   * @param asImpersonatedAdmin checks whether we try to sign in as basic
   * client or as clients adviser, if we are adviser, we get persistence per tab to be able
   * to impersonate multiple clients in different tabs.
   */
  setAccessToken(token: string, asImpersonatedAdmin: boolean): void {
    if (asImpersonatedAdmin) {
      window.sessionStorage.setItem(ACCESS_TOKEN, token);
    }

    window.localStorage.setItem(ACCESS_TOKEN, token);
  }

  tokenValid(): boolean {
    const token = this.token();

    if (token == null) return false;

    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const expirationDate = moment(tokenData.exp * 1000);
      return expirationDate.isAfter(moment());
    } catch (_) {
      return false;
    }
  }

  userIdFromToken(): string | undefined {
    const token = this.token();
    if (token == null) return undefined;

    const tokenData = JSON.parse(atob(token.split('.')[1]));

    return tokenData.sub;
  }

  logout(): void {
    this.store.dispatch(clearClientUser());
    this.store.dispatch(resetStore());
    window.sessionStorage.removeItem(IMPERSONATED);
    window.sessionStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(ACCESS_TOKEN);
  }

  logoutAndRedirect(): void {
    this.logout();
    if (!this.router.url.startsWith('/' + paths.AUTH + '/')) {
      this.router.navigate([paths.AUTH, paths.auth.LOGIN]);
    }
  }
}
