import { UserPageEnum } from '@app/sign-up/enums/user-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const LANDLORD_FLOW = {
  UserCreate: 0,
} as const;

export const LANDLORD_FLOW_STEPS: FlowStepsModel<typeof LANDLORD_FLOW> = {
  [LANDLORD_FLOW.UserCreate]: {
    step: LANDLORD_FLOW.UserCreate,
    name: 'UserCreate',
    page: {
      route: paths.signUp.Landlord,
      name: UserPageEnum.UserCreate,
    },
  },
};
