import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/models/app-state.model';
import { LocalStorageSyncService } from '@shared/services/local-storage-sync.service';
import {
  loadSignUpPropertyFromStorage,
  loadSignUpPropertyFromStorageSuccess,
  saveSignUpPropertyToStorage,
} from '@shared/store/sign-up/property/property.actions';
import { selectSignUpPropertyState } from '@shared/store/sign-up/property/property.selectors';
import { signUpPropertySelectorName } from '@shared/store/sign-up/property/property.state';
import { tap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SignUpPropertyEffects {
  private readonly actions$ = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);
  private readonly localStorageSyncService = inject(LocalStorageSyncService);

  loadPropertyFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSignUpPropertyFromStorage),
      map(() => loadSignUpPropertyFromStorageSuccess({ state: this.localStorageSyncService.loadFromStorage(signUpPropertySelectorName) })),
    ),
  );

  savePropertyToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSignUpPropertyToStorage),
        withLatestFrom(this.store.select(selectSignUpPropertyState)),
        tap(([_, propertyState]) => {
          this.localStorageSyncService.saveToStorage(signUpPropertySelectorName, propertyState);
        }),
      ),
    { dispatch: false },
  );
}
