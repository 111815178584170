import { MortgagePageEnum } from '@app/sign-up/enums/mortgage-page.enum';
import { PropertyPageEnum } from '@app/sign-up/enums/property-page.enum';
import { UserPageEnum } from '@app/sign-up/enums/user-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const MORTGAGE_MONITOR_FLOW = {
  PropertyAddress: 0,
  PropertyAdditionalInfo: 1,
  PropertyOverview: 2,
  MortgageLender: 3,
  MortgageRateAndPeriod: 4,
  MortgageBorrowedAmountAndTerm: 5,
  MortgageMonthlyPayment: 6,
  MortgageProductMatch: 7,
  UserCreate: 8,
} as const;

export const MORTGAGE_MONITOR_FLOW_STEPS: FlowStepsModel<typeof MORTGAGE_MONITOR_FLOW> = {
  [MORTGAGE_MONITOR_FLOW.PropertyAddress]: {
    step: MORTGAGE_MONITOR_FLOW.PropertyAddress,
    name: 'PropertyAddress',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.Address,
    },
  },
  [MORTGAGE_MONITOR_FLOW.PropertyAdditionalInfo]: {
    step: MORTGAGE_MONITOR_FLOW.PropertyAdditionalInfo,
    name: 'PropertyAdditionalInfo',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.AdditionalInfo,
    },
  },
  [MORTGAGE_MONITOR_FLOW.PropertyOverview]: {
    step: MORTGAGE_MONITOR_FLOW.PropertyOverview,
    name: 'PropertyOverview',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.Overview,
    },
  },
  [MORTGAGE_MONITOR_FLOW.MortgageLender]: {
    step: MORTGAGE_MONITOR_FLOW.MortgageLender,
    name: 'MortgageLender',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.Lender,
    },
  },
  [MORTGAGE_MONITOR_FLOW.MortgageRateAndPeriod]: {
    step: MORTGAGE_MONITOR_FLOW.MortgageRateAndPeriod,
    name: 'MortgageRateAndPeriod',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.RateAndPeriod,
    },
  },
  [MORTGAGE_MONITOR_FLOW.MortgageBorrowedAmountAndTerm]: {
    step: MORTGAGE_MONITOR_FLOW.MortgageBorrowedAmountAndTerm,
    name: 'MortgageBorrowedAmountAndTerm',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.BorrowedAmountAndTerm,
    },
  },
  [MORTGAGE_MONITOR_FLOW.MortgageMonthlyPayment]: {
    step: MORTGAGE_MONITOR_FLOW.MortgageMonthlyPayment,
    name: 'MortgageMonthlyPayment',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.MonthlyPayment,
    },
  },
  [MORTGAGE_MONITOR_FLOW.MortgageProductMatch]: {
    step: MORTGAGE_MONITOR_FLOW.MortgageProductMatch,
    name: 'MortgageProductMatch',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.ProductMatch,
    },
  },
  [MORTGAGE_MONITOR_FLOW.UserCreate]: {
    step: MORTGAGE_MONITOR_FLOW.UserCreate,
    name: 'UserCreate',
    page: {
      route: paths.signUp.User,
      name: UserPageEnum.UserCreate,
    },
  },
};
