import { HTTP_X_CompareMortgageProducts_Response_Product } from '@api-new/mortgageservice';
import { SmartSearchFormValues } from '@app/sign-up/utils/smart-search-form';
import { ErrorModel } from '@shared/models/app-state.model';

export interface SignUpSmartSearchState {
  entity: SmartSearchFormValues | null;
  liveResultsProducts: HTTP_X_CompareMortgageProducts_Response_Product[];
  numberOfProducts: number;
  loading: boolean;
  errors: ErrorModel | null;
}

export const signUpSmartSearchSelectorName = 'signUpSmartSearch';
