import { Injectable, inject } from '@angular/core';
import { UserApiService } from '@api-new/userservice';
import { AppState } from '@capacitor/app';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { hexToRgb } from '@shared-lib/utils/chart-utils/modify-hex-color';
import { generateColorScale } from '@shared-lib/utils/colors/generate-color-scale';
import { getSignUpBranding, getSignUpBrandingFailure, getSignUpBrandingSuccess } from '@shared/store/sign-up/branding/branding.actions';
import { selectSignUpBrandingReferralCode } from '@shared/store/sign-up/branding/branding.selectors';
import { tap } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class SignUpBrandingEffects {
  private readonly userApiService = inject(UserApiService);
  private readonly actions$ = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);

  getBranding = createEffect(() =>
    this.actions$.pipe(
      ofType(getSignUpBranding),
      withLatestFrom(this.store.select(selectSignUpBrandingReferralCode)),
      switchMap(([_, referralCode]) =>
        this.userApiService.HTTP_ULC_LoadSignUpBranding({
          referralCode: referralCode ?? undefined,
          userAgent: undefined,
          sessionId: undefined,
        }),
      ),
      map((branding) => getSignUpBrandingSuccess(branding)),
      catchError((error) => of(getSignUpBrandingFailure({ error }))),
    ),
  );

  getBrandingSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSignUpBrandingSuccess),
        tap((branding) => {
          const rgb = hexToRgb(branding.primaryColor);
          const colorScale = generateColorScale(branding.primaryColor);

          document.documentElement.style.setProperty('--color-primary-rgb', `${rgb?.r}, ${rgb?.g}, ${rgb?.b}`);
          document.documentElement.style.setProperty('--primary', `#${branding.primaryColor}`);
          document.documentElement.style.setProperty('--color-primary-50', `${colorScale[50]}`);
          document.documentElement.style.setProperty('--color-primary-100', `${colorScale[100]}`);
          document.documentElement.style.setProperty('--color-primary-700', `${colorScale[200]}`);
        }),
      ),
    { dispatch: false },
  );
}
