import { HTTP_ULC_LoadSignUpBranding_Response } from '@api-new/userservice';
import { createSelector } from '@ngrx/store';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';

export const selectSignUpBrandingState = createSelector(selectSignUpState, (state) => state.branding);

export const selectSignUpBranding = createSelector(
  selectSignUpBrandingState,
  (brandingState): HTTP_ULC_LoadSignUpBranding_Response | null => brandingState.entity,
);

export const selectSignUpBrandingCompanyId = createSelector(selectSignUpBranding, (branding): string | null => branding?.companyId ?? null);

export const selectSignUpBrandingIsLoading = createSelector(selectSignUpBrandingState, (brandingState): boolean => brandingState.loading);

export const selectSignUpBrandingReferralCode = createSelector(
  selectSignUpBrandingState,
  (brandingState): string | null => brandingState.referralCode,
);
