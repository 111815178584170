import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { ErrorModel } from '@shared/models/app-state.model';

export interface SignUpPropertyState {
  entity: PropertyFormValues | null;
  loading: boolean;
  errors: ErrorModel | null;
}

export const signUpPropertySelectorName = 'signUpProperty';
