import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatch, Route, Router, UrlTree } from '@angular/router';
import { paths } from '@platform/paths';
import { Observable } from 'rxjs';
import { FeatureFlag } from '../models/feature-config.model';
import { FeatureFlagsService } from '../services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanMatch, CanActivate {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isEnabled = route.data.isEnabled as FeatureFlag;
    if (isEnabled && this.featureFlagsService.isFeatureEnabled(isEnabled)) {
      return true;
    }
    void this.router.navigate([paths.platform.DASHBOARD]);
    return false;
  }

  canMatch(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isEnabled = route.data.isEnabled as FeatureFlag;
    if (isEnabled && this.featureFlagsService.isFeatureEnabled(isEnabled)) {
      return true;
    }
    void this.router.navigate([paths.platform.DASHBOARD]);
    return false;
  }
}
