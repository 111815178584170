import { createReducer, on } from '@ngrx/store';
import { loadSignUpUserFromStorageSuccess, saveSignUpUserToStorage } from '@shared/store/sign-up/user/user.actions';
import { SignUpUserState } from './user.state';

export const signUpUserInitialState: SignUpUserState = {
  entity: null,
  loading: false,
  errors: null,
};

export const signUpUserReducer = createReducer(
  signUpUserInitialState,
  on(loadSignUpUserFromStorageSuccess, (_, { state }) => ({
    ...(state
      ? {
          ...state,
          entity: state.entity
            ? {
                ...state.entity,
                dateOfBirth: state.entity.dateOfBirth ? new Date(state.entity.dateOfBirth) : null,
              }
            : null,
        }
      : signUpUserInitialState),
  })),
  on(saveSignUpUserToStorage, (state, formData) => ({
    ...state,
    entity: formData,
    loading: false,
  })),
);
