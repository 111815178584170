import { Injectable } from '@angular/core';
import { MessageService, ToastMessageOptions } from 'primeng/api';

export enum ToastAction {
  action_create = 'action_create',
  action_update = 'action_update',
  action_delete = 'action_delete',
  action_activate = 'action_activate',
  action_deactivate = 'action_deactivate',
  action_reassign = 'action_reassign',
  action_close = 'action_close',
  action_copy = 'action_copy',
  action_email = 'action_email',
}

export enum ToastSeverity {
  success = 'success',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

const ToastSeverityMap = new Map([
  [ToastSeverity.error, 'Error'],
  [ToastSeverity.info, 'Info'],
  [ToastSeverity.warn, 'Warning'],
  [ToastSeverity.success, 'Success'],
]);

@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly defaultToastBehavior: Partial<ToastMessageOptions> = {
    life: 2000,
  };

  constructor(private readonly messageService: MessageService) {}

  showToast(severity: ToastSeverity, message: string, options?: Partial<ToastMessageOptions>): void {
    this.messageService.add({
      ...this.defaultToastBehavior,
      ...options,
      severity: severity,
      detail: options?.detail || message,
      summary: options?.summary || ToastSeverityMap.get(severity),
    });
  }
}
