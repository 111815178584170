import { EntryPageEnum } from '@app/sign-up/enums/entry-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const ENTRY_FLOW = {
  Landing: -12,
  BuyerType: -11,
  MortgageSwitchUrgency: -10,
} as const;

export const ENTRY_FLOW_STEPS: FlowStepsModel<typeof ENTRY_FLOW> = {
  [ENTRY_FLOW.Landing]: {
    step: ENTRY_FLOW.Landing,
    name: 'Landing',
    page: {
      route: paths.signUp.Entry,
      name: EntryPageEnum.Landing,
    },
  },
  [ENTRY_FLOW.BuyerType]: {
    step: ENTRY_FLOW.BuyerType,
    name: 'BuyerType',
    page: {
      route: paths.signUp.Entry,
      name: EntryPageEnum.BuyerType,
    },
  },
  [ENTRY_FLOW.MortgageSwitchUrgency]: {
    step: ENTRY_FLOW.MortgageSwitchUrgency,
    name: 'MortgageSwitchUrgency',
    page: {
      route: paths.signUp.Entry,
      name: EntryPageEnum.MortgageSwitchUrgency,
    },
  },
};
