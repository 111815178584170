import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { getFlowStep } from '@app/sign-up/utils/flow.utils';
import { createSelector } from '@ngrx/store';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';
import { EntryFormValuesModel } from './flow.state';

export const selectSignUpFlowState = createSelector(selectSignUpState, (state) => state.flow);

export const selectSignUpFlowData = createSelector(selectSignUpFlowState, (flowState): EntryFormValuesModel => flowState.entity);

export const selectSignUpFlowIsPreviousDisabled = createSelector(
  selectSignUpFlowState,
  (flowState): boolean => flowState.currentStep === flowState.initialStep,
);

export const selectSignUpFlowCurrentPageName = createSelector(
  selectSignUpFlowState,
  (flowState): string | null => getFlowStep(flowState.entity.creationMethod, flowState.currentStep)?.page.name ?? null,
);

export const selectSignUpFlowCreationMethod = createSelector(
  selectSignUpFlowData,
  (data): HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod | null => data.creationMethod,
);
