import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { getFlowStep } from '@app/sign-up/utils/flow.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { AppState } from '@shared/models/app-state.model';
import { LocalStorageSyncService } from '@shared/services/local-storage-sync.service';
import {
  loadSignUpFlowFromStorage,
  loadSignUpFlowFromStorageSuccess,
  setSignUpFlowCurrentStep,
  setSignUpFlowData,
  setSignUpFlowInitialStep,
  signUpFlowNextStep,
  signUpFlowPreviousStep,
} from '@shared/store/sign-up/flow/flow.actions';
import { selectSignUpFlowState } from '@shared/store/sign-up/flow/flow.selectors';
import { signUpFlowSelectorName } from '@shared/store/sign-up/flow/flow.state';
import { getNextStep } from '@shared/store/sign-up/flow/utils/get-next-step';
import { getPreviousStep } from '@shared/store/sign-up/flow/utils/get-previous-step';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';
import { take, tap, timer, withLatestFrom } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SignUpFlowEffects {
  private readonly store: Store<AppState> = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly localStorageSyncService = inject(LocalStorageSyncService);
  private readonly router = inject(Router);

  loadFlowFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSignUpFlowFromStorage),
      map(() => loadSignUpFlowFromStorageSuccess({ state: this.localStorageSyncService.loadFromStorage(signUpFlowSelectorName) })),
    ),
  );

  saveFlowToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSignUpFlowData, setSignUpFlowInitialStep, setSignUpFlowCurrentStep),
        withLatestFrom(this.store.select(selectSignUpFlowState)),
        tap(([_, flowState]) => {
          this.localStorageSyncService.saveToStorage(signUpFlowSelectorName, flowState);
        }),
      ),
    { dispatch: false },
  );

  flowPreviousStep = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpFlowPreviousStep),
      withLatestFrom(this.store.select(selectSignUpState)),
      filter(
        ([_, appState]) => appState.flow.currentStep !== appState.flow.initialStep && appState.flow.currentStep !== ENTRY_FLOW.Landing,
      ),
      map(([_, appState]) => {
        return setSignUpFlowCurrentStep({ step: getPreviousStep(appState) });
      }),
    ),
  );

  flowNextStep = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpFlowNextStep),
      withLatestFrom(this.store.select(selectSignUpState)),
      map(([_, appState]) => {
        return setSignUpFlowCurrentStep({ step: getNextStep(appState) });
      }),
    ),
  );

  flowRouter = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSignUpFlowCurrentStep, loadSignUpFlowFromStorageSuccess),
        withLatestFrom(this.store.select(selectSignUpFlowState)),
        switchMap(([_, flowState]) =>
          timer(0, 50).pipe(
            filter(() => this.router.navigated),
            take(1),
            map(() => {
              if (location.pathname.includes(paths.signUp.WelcomeEmail)) return;

              const currentStep = getFlowStep(flowState.entity.creationMethod, flowState.currentStep);
              if (currentStep == null) {
                this.resetFlow();
                return;
              }

              const currentPath = this.router.url.split('/').at(-1);
              if (currentStep.page.route === currentPath) {
                // Everything is fine
                return;
              } else {
                // Navigate to correct page
                void this.router.navigate(['/', paths.SIGN_UP, currentStep.page.route], { queryParamsHandling: 'preserve' });
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private resetFlow(): void {
    this.store.dispatch(setSignUpFlowCurrentStep({ step: ENTRY_FLOW.Landing }));
    void this.router.navigate([paths.signUp.Entry], { queryParamsHandling: 'preserve' });
  }
}
