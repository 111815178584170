import { Injectable } from '@angular/core';
import { HTTP_AP_ActivateReferrals_Request } from '@api-new/userservice/HTTP_AP_ActivateReferrals_Request';
import { HTTP_AP_AddManagedAdvisorUsers_Request } from '@api-new/userservice/HTTP_AP_AddManagedAdvisorUsers_Request';
import { HTTP_AP_AddManagedAdvisorUsers_Response } from '@api-new/userservice/HTTP_AP_AddManagedAdvisorUsers_Response';
import { HTTP_AP_AdvisorUser } from '@api-new/userservice/HTTP_AP_AdvisorUser';
import { HTTP_AP_AdvisorUserPermissionSettings } from '@api-new/userservice/HTTP_AP_AdvisorUserPermissionSettings';
import { HTTP_AP_BulkCreateReferrals_Request } from '@api-new/userservice/HTTP_AP_BulkCreateReferrals_Request';
import { HTTP_AP_ClientUser } from '@api-new/userservice/HTTP_AP_ClientUser';
import { HTTP_AP_ClientUserEmployment } from '@api-new/userservice/HTTP_AP_ClientUserEmployment';
import { HTTP_AP_CreateAdvisorUser_Request } from '@api-new/userservice/HTTP_AP_CreateAdvisorUser_Request';
import { HTTP_AP_CreateClientUser_Request } from '@api-new/userservice/HTTP_AP_CreateClientUser_Request';
import { HTTP_AP_CreateReferral_Request } from '@api-new/userservice/HTTP_AP_CreateReferral_Request';
import { HTTP_AP_CreateTinyUrl_Request } from '@api-new/userservice/HTTP_AP_CreateTinyUrl_Request';
import { HTTP_AP_DeactivateReferrals_Request } from '@api-new/userservice/HTTP_AP_DeactivateReferrals_Request';
import { HTTP_AP_DeleteAdvisorUser_Request } from '@api-new/userservice/HTTP_AP_DeleteAdvisorUser_Request';
import { HTTP_AP_DeleteClientUser_Request } from '@api-new/userservice/HTTP_AP_DeleteClientUser_Request';
import { HTTP_AP_DeleteTinyUrl_Request } from '@api-new/userservice/HTTP_AP_DeleteTinyUrl_Request';
import { HTTP_AP_ExportClientUsersToCSV_Request } from '@api-new/userservice/HTTP_AP_ExportClientUsersToCSV_Request';
import { HTTP_AP_ExportReferralsToCSV_Request } from '@api-new/userservice/HTTP_AP_ExportReferralsToCSV_Request';
import { HTTP_AP_GetAdvisorUserData_ForManagement_Request } from '@api-new/userservice/HTTP_AP_GetAdvisorUserData_ForManagement_Request';
import { HTTP_AP_GetAdvisorUserData_ForManagement_Response } from '@api-new/userservice/HTTP_AP_GetAdvisorUserData_ForManagement_Response';
import { HTTP_AP_GetAdvisorUser_Request } from '@api-new/userservice/HTTP_AP_GetAdvisorUser_Request';
import { HTTP_AP_GetClientUserEmployment_Request } from '@api-new/userservice/HTTP_AP_GetClientUserEmployment_Request';
import { HTTP_AP_GetClientUser_FortClientHeader_Request } from '@api-new/userservice/HTTP_AP_GetClientUser_FortClientHeader_Request';
import { HTTP_AP_GetClientUser_FortClientHeader_Response } from '@api-new/userservice/HTTP_AP_GetClientUser_FortClientHeader_Response';
import { HTTP_AP_GetClientUser_Request } from '@api-new/userservice/HTTP_AP_GetClientUser_Request';
import { HTTP_AP_GetReferral_Request } from '@api-new/userservice/HTTP_AP_GetReferral_Request';
import { HTTP_AP_GetTinyUrl_Request } from '@api-new/userservice/HTTP_AP_GetTinyUrl_Request';
import { HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Request } from '@api-new/userservice/HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Request';
import { HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Response } from '@api-new/userservice/HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Response';
import { HTTP_AP_ListAdvisorUsers_ForManagement_Request } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForManagement_Request';
import { HTTP_AP_ListAdvisorUsers_ForManagement_Response } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForManagement_Response';
import { HTTP_AP_ListAdvisorUsers_ForSelect_Request } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForSelect_Request';
import { HTTP_AP_ListAdvisorUsers_ForSelect_Response } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForSelect_Response';
import { HTTP_AP_ListAdvisorUsers_ForTable_Request } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForTable_Request';
import { HTTP_AP_ListAdvisorUsers_ForTable_Response } from '@api-new/userservice/HTTP_AP_ListAdvisorUsers_ForTable_Response';
import { HTTP_AP_ListClientUserEmails_Request } from '@api-new/userservice/HTTP_AP_ListClientUserEmails_Request';
import { HTTP_AP_ListClientUserEmails_Response } from '@api-new/userservice/HTTP_AP_ListClientUserEmails_Response';
import { HTTP_AP_ListClientUserIds_Request } from '@api-new/userservice/HTTP_AP_ListClientUserIds_Request';
import { HTTP_AP_ListClientUserIds_Response } from '@api-new/userservice/HTTP_AP_ListClientUserIds_Response';
import { HTTP_AP_ListClientUsers_ForBulkDeletion_Request } from '@api-new/userservice/HTTP_AP_ListClientUsers_ForBulkDeletion_Request';
import { HTTP_AP_ListClientUsers_ForBulkDeletion_Response } from '@api-new/userservice/HTTP_AP_ListClientUsers_ForBulkDeletion_Response';
import { HTTP_AP_ListClientUsers_ForTable_Request } from '@api-new/userservice/HTTP_AP_ListClientUsers_ForTable_Request';
import { HTTP_AP_ListClientUsers_ForTable_Response } from '@api-new/userservice/HTTP_AP_ListClientUsers_ForTable_Response';
import { HTTP_AP_ListReferrals_Request } from '@api-new/userservice/HTTP_AP_ListReferrals_Request';
import { HTTP_AP_ListReferrals_Response } from '@api-new/userservice/HTTP_AP_ListReferrals_Response';
import { HTTP_AP_ListTinyUrls_Request } from '@api-new/userservice/HTTP_AP_ListTinyUrls_Request';
import { HTTP_AP_ListTinyUrls_Response } from '@api-new/userservice/HTTP_AP_ListTinyUrls_Response';
import { HTTP_AP_PurgeClientUsers_Request } from '@api-new/userservice/HTTP_AP_PurgeClientUsers_Request';
import { HTTP_AP_PurgeClientUsers_Response } from '@api-new/userservice/HTTP_AP_PurgeClientUsers_Response';
import { HTTP_AP_ReassignAdvisorUsers_Request } from '@api-new/userservice/HTTP_AP_ReassignAdvisorUsers_Request';
import { HTTP_AP_ReassignClientUsers_Request } from '@api-new/userservice/HTTP_AP_ReassignClientUsers_Request';
import { HTTP_AP_Referral } from '@api-new/userservice/HTTP_AP_Referral';
import { HTTP_AP_RemoveManagedAdvisorUsers_Request } from '@api-new/userservice/HTTP_AP_RemoveManagedAdvisorUsers_Request';
import { HTTP_AP_TinyUrl } from '@api-new/userservice/HTTP_AP_TinyUrl';
import { HTTP_AP_UpdateAdvisorUserEmail_Request } from '@api-new/userservice/HTTP_AP_UpdateAdvisorUserEmail_Request';
import { HTTP_AP_UpdateAdvisorUserPermissionSettings_Request } from '@api-new/userservice/HTTP_AP_UpdateAdvisorUserPermissionSettings_Request';
import { HTTP_AP_UpdateAdvisorUserPhoneNumber_Request } from '@api-new/userservice/HTTP_AP_UpdateAdvisorUserPhoneNumber_Request';
import { HTTP_AP_UpdateAdvisorUser_Request } from '@api-new/userservice/HTTP_AP_UpdateAdvisorUser_Request';
import { HTTP_AP_UpdateClientUserEmail_Request } from '@api-new/userservice/HTTP_AP_UpdateClientUserEmail_Request';
import { HTTP_AP_UpdateClientUserEmployment_Request } from '@api-new/userservice/HTTP_AP_UpdateClientUserEmployment_Request';
import { HTTP_AP_UpdateClientUserPhoneNumber_Request } from '@api-new/userservice/HTTP_AP_UpdateClientUserPhoneNumber_Request';
import { HTTP_AP_UpdateClientUser_Request } from '@api-new/userservice/HTTP_AP_UpdateClientUser_Request';
import { HTTP_AP_UpdateReferral_Request } from '@api-new/userservice/HTTP_AP_UpdateReferral_Request';
import { HTTP_AP_UpdateTinyUrl_Request } from '@api-new/userservice/HTTP_AP_UpdateTinyUrl_Request';
import { HTTP_CP_AdvisorUser } from '@api-new/userservice/HTTP_CP_AdvisorUser';
import { HTTP_CP_ClientUser } from '@api-new/userservice/HTTP_CP_ClientUser';
import { HTTP_CP_DoesEmailExist_Request } from '@api-new/userservice/HTTP_CP_DoesEmailExist_Request';
import { HTTP_CP_DoesEmailExist_Response } from '@api-new/userservice/HTTP_CP_DoesEmailExist_Response';
import { HTTP_CP_UpdateClientUser_Request } from '@api-new/userservice/HTTP_CP_UpdateClientUser_Request';
import { HTTP_ULC_DoesEmailExist_Request } from '@api-new/userservice/HTTP_ULC_DoesEmailExist_Request';
import { HTTP_ULC_DoesEmailExist_Response } from '@api-new/userservice/HTTP_ULC_DoesEmailExist_Response';
import { HTTP_ULC_LoadSignUpBranding_Request } from '@api-new/userservice/HTTP_ULC_LoadSignUpBranding_Request';
import { HTTP_ULC_LoadSignUpBranding_Response } from '@api-new/userservice/HTTP_ULC_LoadSignUpBranding_Response';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_ActivateReferrals(request: HTTP_AP_ActivateReferrals_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/referrals/all/Activate`, request);
  }

  HTTP_AP_AddManagedAdvisorUsers(request: HTTP_AP_AddManagedAdvisorUsers_Request): Observable<HTTP_AP_AddManagedAdvisorUsers_Response> {
    return this.httpClient.post(`/user-service/ap/advisor-users/all/AddManaged`, request);
  }

  HTTP_AP_BulkCreateReferrals(request: HTTP_AP_BulkCreateReferrals_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/referrals/all/BulkCreate`, request);
  }

  HTTP_AP_CreateAdvisorUser(request: HTTP_AP_CreateAdvisorUser_Request): Observable<HTTP_AP_AdvisorUser> {
    return this.httpClient.post(`/user-service/ap/advisor-users`, request);
  }

  HTTP_AP_CreateClientUser(request: HTTP_AP_CreateClientUser_Request): Observable<HTTP_AP_ClientUser> {
    return this.httpClient.post(`/user-service/ap/client-users`, request);
  }

  HTTP_AP_CreateReferral(request: HTTP_AP_CreateReferral_Request): Observable<HTTP_AP_Referral> {
    return this.httpClient.post(`/user-service/ap/referrals`, request);
  }

  HTTP_AP_CreateTinyUrl(request: HTTP_AP_CreateTinyUrl_Request): Observable<HTTP_AP_TinyUrl> {
    return this.httpClient.post(`/user-service/ap/tiny-urls`, request);
  }

  HTTP_AP_DeactivateReferrals(request: HTTP_AP_DeactivateReferrals_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/referrals/all/Deactivate`, request);
  }

  HTTP_AP_DeleteAdvisorUser(request: HTTP_AP_DeleteAdvisorUser_Request): Observable<undefined> {
    const { advisorUserId, ...updatedRequest } = request;
    return this.httpClient.delete(`/user-service/ap/advisor-users/${request.advisorUserId}`, { params: updatedRequest });
  }

  HTTP_AP_DeleteClientUser(request: HTTP_AP_DeleteClientUser_Request): Observable<undefined> {
    const { clientUserId, ...updatedRequest } = request;
    return this.httpClient.delete(`/user-service/ap/client-users/${request.clientUserId}`, { params: updatedRequest });
  }

  HTTP_AP_DeleteTinyUrl(request: HTTP_AP_DeleteTinyUrl_Request): Observable<undefined> {
    const { tinyUrlId, ...updatedRequest } = request;
    return this.httpClient.delete(`/user-service/ap/tiny-urls/${request.tinyUrlId}`, { params: updatedRequest });
  }

  HTTP_AP_ExportClientUsersToCSV(request: HTTP_AP_ExportClientUsersToCSV_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/client-users/all/ExportToCSV`, request);
  }

  HTTP_AP_ExportReferralsToCSV(request: HTTP_AP_ExportReferralsToCSV_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/referrals/all/ExportToCSV`, request);
  }

  HTTP_AP_GetAdvisorUser(request: HTTP_AP_GetAdvisorUser_Request): Observable<HTTP_AP_AdvisorUser> {
    const { advisorUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/advisor-users/${request.advisorUserId}`, { params: updatedRequest });
  }

  HTTP_AP_GetAdvisorUserData_ForManagement(
    request: HTTP_AP_GetAdvisorUserData_ForManagement_Request,
  ): Observable<HTTP_AP_GetAdvisorUserData_ForManagement_Response> {
    const { advisorUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/advisor-users/${request.advisorUserId}/ForManagement`, { params: updatedRequest });
  }

  HTTP_AP_GetClientUser(request: HTTP_AP_GetClientUser_Request): Observable<HTTP_AP_ClientUser> {
    const { clientUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/client-users/${request.clientUserId}`, { params: updatedRequest });
  }

  HTTP_AP_GetClientUser_FortClientHeader(
    request: HTTP_AP_GetClientUser_FortClientHeader_Request,
  ): Observable<HTTP_AP_GetClientUser_FortClientHeader_Response> {
    const { clientUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/client-users/${request.clientUserId}/ForClientHeader`, { params: updatedRequest });
  }

  HTTP_AP_GetClientUserEmployment(request: HTTP_AP_GetClientUserEmployment_Request): Observable<HTTP_AP_ClientUserEmployment> {
    const { clientUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/client-users/${request.clientUserId}/client-user-employment`, { params: updatedRequest });
  }

  HTTP_AP_GetReferral(request: HTTP_AP_GetReferral_Request): Observable<HTTP_AP_Referral> {
    const { referralId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/referrals/${request.referralId}`, { params: updatedRequest });
  }

  HTTP_AP_GetTinyUrl(request: HTTP_AP_GetTinyUrl_Request): Observable<HTTP_AP_TinyUrl> {
    const { tinyUrlId, ...updatedRequest } = request;
    return this.httpClient.get(`/user-service/ap/tiny-urls/${request.tinyUrlId}`, { params: updatedRequest });
  }

  HTTP_AP_ListAdvisorUserPermissionSettingsTemplates(
    request: HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Request,
  ): Observable<HTTP_AP_ListAdvisorUserPermissionSettingsTemplates_Response> {
    return this.httpClient.get(`/user-service/ap/advisor-users/all/PermissionSettingsTemplates`, { params: request });
  }

  HTTP_AP_ListAdvisorUsers_ForManagement(
    request: HTTP_AP_ListAdvisorUsers_ForManagement_Request,
  ): Observable<HTTP_AP_ListAdvisorUsers_ForManagement_Response> {
    return this.httpClient.get(`/user-service/ap/advisor-users/all/ForManagement`, { params: request });
  }

  HTTP_AP_ListAdvisorUsers_ForSelect(
    request: HTTP_AP_ListAdvisorUsers_ForSelect_Request,
  ): Observable<HTTP_AP_ListAdvisorUsers_ForSelect_Response> {
    return this.httpClient.get(`/user-service/ap/advisor-users/all/ForSelect`, { params: request });
  }

  HTTP_AP_ListAdvisorUsers_ForTable(
    request: HTTP_AP_ListAdvisorUsers_ForTable_Request,
  ): Observable<HTTP_AP_ListAdvisorUsers_ForTable_Response> {
    return this.httpClient.get(`/user-service/ap/advisor-users/all/ForTable`, { params: request });
  }

  HTTP_AP_ListClientUserEmails(request: HTTP_AP_ListClientUserEmails_Request): Observable<HTTP_AP_ListClientUserEmails_Response> {
    return this.httpClient.post(`/user-service/ap/client-users/all/email`, request);
  }

  HTTP_AP_ListClientUserIds(request: HTTP_AP_ListClientUserIds_Request): Observable<HTTP_AP_ListClientUserIds_Response> {
    return this.httpClient.post(`/user-service/ap/client-users/all/id`, request);
  }

  HTTP_AP_ListClientUsers_ForBulkDeletion(
    request: HTTP_AP_ListClientUsers_ForBulkDeletion_Request,
  ): Observable<HTTP_AP_ListClientUsers_ForBulkDeletion_Response> {
    return this.httpClient.post(`/user-service/ap/client-users/all/ForBulkDeletion`, request);
  }

  HTTP_AP_ListClientUsers_ForTable(
    request: HTTP_AP_ListClientUsers_ForTable_Request,
  ): Observable<HTTP_AP_ListClientUsers_ForTable_Response> {
    return this.httpClient.get(`/user-service/ap/client-users/all/ForTable`, { params: request });
  }

  HTTP_AP_ListReferrals(request: HTTP_AP_ListReferrals_Request): Observable<HTTP_AP_ListReferrals_Response> {
    return this.httpClient.get(`/user-service/ap/referrals`, { params: request });
  }

  HTTP_AP_ListTinyUrls(request: HTTP_AP_ListTinyUrls_Request): Observable<HTTP_AP_ListTinyUrls_Response> {
    return this.httpClient.get(`/user-service/ap/tiny-urls`, { params: request });
  }

  HTTP_AP_PurgeClientUsers(request: HTTP_AP_PurgeClientUsers_Request): Observable<HTTP_AP_PurgeClientUsers_Response> {
    return this.httpClient.delete(`/user-service/ap/client-users/all/Purge`, { params: request });
  }

  HTTP_AP_ReassignAdvisorUsers(request: HTTP_AP_ReassignAdvisorUsers_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/advisor-users/all/Reassign`, request);
  }

  HTTP_AP_ReassignClientUsers(request: HTTP_AP_ReassignClientUsers_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/client-users/all/Reassign`, request);
  }

  HTTP_AP_RemoveManagedAdvisorUsers(request: HTTP_AP_RemoveManagedAdvisorUsers_Request): Observable<undefined> {
    return this.httpClient.post(`/user-service/ap/advisor-users/all/RemoveManaged`, request);
  }

  HTTP_AP_UpdateAdvisorUser(request: HTTP_AP_UpdateAdvisorUser_Request): Observable<HTTP_AP_AdvisorUser> {
    return this.httpClient.put(`/user-service/ap/advisor-users/${request.advisorUserId}`, request);
  }

  HTTP_AP_UpdateAdvisorUserPermissionSettings(
    request: HTTP_AP_UpdateAdvisorUserPermissionSettings_Request,
  ): Observable<HTTP_AP_AdvisorUserPermissionSettings> {
    return this.httpClient.put(`/user-service/ap/advisor-users/${request.advisorUserId}/advisor-user-permission-settings`, request);
  }

  HTTP_AP_UpdateAdvisorUserEmail(request: HTTP_AP_UpdateAdvisorUserEmail_Request): Observable<undefined> {
    return this.httpClient.patch(`/user-service/ap/advisor-users/${request.advisorUserId}/email`, request);
  }

  HTTP_AP_UpdateAdvisorUserPhoneNumber(request: HTTP_AP_UpdateAdvisorUserPhoneNumber_Request): Observable<undefined> {
    return this.httpClient.patch(`/user-service/ap/advisor-users/${request.advisorUserId}/phone-number`, request);
  }

  HTTP_AP_UpdateClientUser(request: HTTP_AP_UpdateClientUser_Request): Observable<HTTP_AP_ClientUser> {
    return this.httpClient.put(`/user-service/ap/client-users/${request.clientUserId}`, request);
  }

  HTTP_AP_UpdateClientUserEmail(request: HTTP_AP_UpdateClientUserEmail_Request): Observable<undefined> {
    return this.httpClient.patch(`/user-service/ap/client-users/${request.clientUserId}/email`, request);
  }

  HTTP_AP_UpdateClientUserEmployment(request: HTTP_AP_UpdateClientUserEmployment_Request): Observable<HTTP_AP_ClientUserEmployment> {
    return this.httpClient.put(`/user-service/ap/client-users/${request.clientUserId}/client-user-employment`, request);
  }

  HTTP_AP_UpdateClientUserPhoneNumber(request: HTTP_AP_UpdateClientUserPhoneNumber_Request): Observable<undefined> {
    return this.httpClient.patch(`/user-service/ap/client-users/${request.clientUserId}/phone-number`, request);
  }

  HTTP_AP_UpdateReferral(request: HTTP_AP_UpdateReferral_Request): Observable<HTTP_AP_Referral> {
    return this.httpClient.put(`/user-service/ap/referrals/${request.referralId}`, request);
  }

  HTTP_AP_UpdateTinyUrl(request: HTTP_AP_UpdateTinyUrl_Request): Observable<HTTP_AP_TinyUrl> {
    return this.httpClient.put(`/user-service/ap/tiny-urls/${request.tinyUrlId}`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_AcceptTermsAndConditions(request?: undefined): Observable<undefined> {
    return this.httpClient.post(`/user-service/cp/client-users/one/AcceptTermsAndConditions`, request);
  }

  HTTP_CP_DoesEmailExist(request: HTTP_CP_DoesEmailExist_Request): Observable<HTTP_CP_DoesEmailExist_Response> {
    return this.httpClient.post(`/user-service/cp/DoesEmailExist`, request);
  }

  HTTP_CP_GetAdvisorUser(request?: undefined): Observable<HTTP_CP_AdvisorUser> {
    return this.httpClient.get(`/user-service/cp/advisor-users/one`, { params: request });
  }

  HTTP_CP_GetClientUser(request?: undefined): Observable<HTTP_CP_ClientUser> {
    return this.httpClient.get(`/user-service/cp/client-users/one`, { params: request });
  }

  HTTP_CP_UpdateClientUser(request: HTTP_CP_UpdateClientUser_Request): Observable<HTTP_CP_ClientUser> {
    return this.httpClient.put(`/user-service/cp/client-users/one`, request);
  }

  HTTP_CP_UpdateClientUser_DismissPropertyBanner(request?: undefined): Observable<HTTP_CP_ClientUser> {
    return this.httpClient.post(`/user-service/cp/client-users/one/DismissPropertyBanner`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_ULC_DoesEmailExist(request: HTTP_ULC_DoesEmailExist_Request): Observable<HTTP_ULC_DoesEmailExist_Response> {
    return this.httpClient.post(`/user-service/ulc/DoesEmailExist`, request);
  }

  HTTP_ULC_LoadSignUpBranding(request: HTTP_ULC_LoadSignUpBranding_Request): Observable<HTTP_ULC_LoadSignUpBranding_Response> {
    return this.httpClient.post(`/user-service/ulc/LoadSignUpBranding`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
