import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { FIRST_TIME_BUYER_FLOW } from '@app/sign-up/constants/first-time-buyer-flow';
import { LANDLORD_FLOW } from '@app/sign-up/constants/landlord-flow';
import { MORTGAGE_MONITOR_FLOW } from '@app/sign-up/constants/mortgage-monitor-flow';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { EntryLandingEnum } from '@app/sign-up/pages/entry/components/entry-landing/enums/entry-landing.enum';
import { ErrorModel } from '@shared/models/app-state.model';

export type Flow = typeof MORTGAGE_MONITOR_FLOW | typeof FIRST_TIME_BUYER_FLOW | typeof ENTRY_FLOW;
export type FlowSteps =
  | FlowStepsModel<typeof MORTGAGE_MONITOR_FLOW>
  | FlowStepsModel<typeof FIRST_TIME_BUYER_FLOW>
  | FlowStepsModel<typeof ENTRY_FLOW>
  | FlowStepsModel<typeof LANDLORD_FLOW>;

export interface EntryFormValuesModel {
  landing: EntryLandingEnum | null;
  creationMethod: HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod | null;
}

export interface SignUpFlowState {
  entity: EntryFormValuesModel;
  initialStep: number;
  currentStep: number;
  errors: ErrorModel | null;
}

export const signUpFlowSelectorName = 'signUpFlow';
