import { MortgageFormValues } from '@app/sign-up/utils/mortgage-form';
import { createAction, props } from '@ngrx/store';
import { SignUpMortgageState } from '@shared/store/sign-up/mortgage/mortgage.state';

export const loadSignUpMortgageFromStorage = createAction('[SIGN UP] Load Mortgage From Storage');
export const loadSignUpMortgageFromStorageSuccess = createAction(
  '[SIGN UP] Load Mortgage From Storage Success',
  props<{ state: SignUpMortgageState | null }>(),
);

export const saveSignUpMortgageToStorage = createAction('[SIGN UP] Save Mortgage To Storage', props<MortgageFormValues>());

export const skipSignUpMortgageCreation = createAction('[SIGN UP] Skip Mortgage Creation');
