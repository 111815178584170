import { createReducer, on } from '@ngrx/store';
import {
  getSignUpBranding,
  getSignUpBrandingFailure,
  getSignUpBrandingSuccess,
  setSignUpBrandingReferralCode,
} from '@shared/store/sign-up/branding/branding.actions';
import { SignUpBrandingState } from './branding.state';

export const signUpBrandingInitialState: SignUpBrandingState = {
  entity: null,
  referralCode: null,
  loading: false,
  errors: null,
};

export const signUpBrandingReducer = createReducer(
  signUpBrandingInitialState,
  on(getSignUpBranding, (state) => ({
    ...state,
    loading: true,
  })),
  on(getSignUpBrandingSuccess, (state, brandingResponse) => ({
    ...state,
    entity: brandingResponse,
    loading: false,
  })),
  on(getSignUpBrandingFailure, (state, error) => ({
    ...state,
    error,
    loading: false,
  })),
  on(setSignUpBrandingReferralCode, (state, { referralCode }) => ({ ...state, referralCode })),
);
