import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { createAction, props } from '@ngrx/store';
import { SignUpPropertyState } from '@shared/store/sign-up/property/property.state';

export const loadSignUpPropertyFromStorage = createAction('[SIGN UP] Load Property From Storage');
export const loadSignUpPropertyFromStorageSuccess = createAction(
  '[SIGN UP] Load Property From Storage Success',
  props<{ state: SignUpPropertyState | null }>(),
);

export const saveSignUpPropertyToStorage = createAction('[SIGN UP] Save Property To Storage', props<PropertyFormValues>());
