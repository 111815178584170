import { CreateUserFormValues } from '@client-ui/components/forms/create-user-form/utils/form-utils';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';
import { SignUpUserState } from '@shared/store/sign-up/user/user.state';

export const loadSignUpUserFromStorage = createAction('[SIGN UP] Load User From Storage');
export const loadSignUpUserFromStorageSuccess = createAction(
  '[SIGN UP] Load User From Storage Success',
  props<{ state: SignUpUserState | null }>(),
);

export const saveSignUpUserToStorage = createAction('[SIGN UP] Save User To Storage', props<CreateUserFormValues>());

export const createSignUpUser = createAction('[SIGN UP] Create User');
export const createSignUpUserSuccess = createAction('[SIGN UP] Create User Success');
export const createSignUpUserFailure = createAction('[SIGN UP] Create User Failure', props<{ error: ErrorModel }>());
