import { enableProdMode } from '@angular/core';
import { loadGoogleMapsScript } from '@shared/scripts/google-maps-scripts';
import 'hammerjs';
import moment from 'moment';
import 'moment-duration-format';
import { environment } from './environments/environment';

moment.fn.toJSON = function (): string {
  return this.format('YYYY-MM-DD[T]HH:mm:ss[+00:00]');
};

if (environment.production) {
  enableProdMode();
}

// Load Google Maps script dynamically before bootstrapping the Angular app
loadGoogleMapsScript().catch((error) => {
  console.error('Error loading Google Maps script:', error);
});
