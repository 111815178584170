import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { AppState } from '../models/app-state.model';

@Injectable()
export class DashlyHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
  ) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // assets needs to use local in develop
    if (req.url.search('assets') >= 0 || req.url.startsWith('https://storage.googleapis.com')) {
      return next.handle(req);
    }

    const token = this.authService.token();

    if (token == null) {
      return next.handle(req.clone());
    }

    switch (req.method) {
      case 'GET':
        return next.handle(
          req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          }),
        );
      case 'POST':
      case 'DELETE':
      case 'PUT':
      case 'PATCH':
        return next.handle(
          req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
            body: {
              ...req.body,
            },
          }),
        );

      default:
        return next.handle(
          req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          }),
        );
    }
  }
}
