import { createReducer, on } from '@ngrx/store';
import { loadSignUpMortgageFromStorageSuccess, saveSignUpMortgageToStorage } from '@shared/store/sign-up/mortgage/mortgage.actions';
import { SignUpMortgageState } from './mortgage.state';

export const signUpMortgageInitialState: SignUpMortgageState = {
  entity: null,
  loading: false,
  errors: null,
};

export const signUpMortgageReducer = createReducer(
  signUpMortgageInitialState,
  on(loadSignUpMortgageFromStorageSuccess, (_, { state }) => ({
    ...(state
      ? {
          ...state,
          entity: state.entity
            ? {
                ...state.entity,
                lenderAndStartDate: { ...state.entity.lenderAndStartDate, startDate: new Date(state.entity.lenderAndStartDate.startDate) },
                mortgageParts: {
                  ...state.entity.mortgageParts,
                  parts: state.entity.mortgageParts.parts.map((part) => ({
                    ...part,
                    startDate: part.startDate ? new Date(part.startDate) : null,
                  })),
                },
              }
            : null,
        }
      : signUpMortgageInitialState),
  })),
  on(saveSignUpMortgageToStorage, (state, formData) => ({
    ...state,
    entity: {
      lenderAndStartDate: formData.lenderAndStartDate,
      rateTypeAndRatePeriod: formData.rateTypeAndRatePeriod,
      mortgageParts: formData.mortgageParts,
      monthlyPayment: formData.monthlyPayment,
      productMatch: formData.productMatch,
    },
    loading: false,
  })),
);
