import { combineReducers } from '@ngrx/store';
import { SignUpBrandingEffects } from '@shared/store/sign-up/branding/branding.effects';
import { signUpBrandingReducer } from '@shared/store/sign-up/branding/branding.reducer';
import { SignUpFlowEffects } from '@shared/store/sign-up/flow/flow.effects';
import { signUpFlowReducer } from '@shared/store/sign-up/flow/flow.reducer';
import { SignUpMortgageEffects } from '@shared/store/sign-up/mortgage/mortgage.effects';
import { signUpMortgageReducer } from '@shared/store/sign-up/mortgage/mortgage.reducer';
import { SignUpPropertyEffects } from '@shared/store/sign-up/property/property.effects';
import { signUpPropertyReducer } from '@shared/store/sign-up/property/property.reducer';
import { SignUpSmartSearchEffects } from '@shared/store/sign-up/smart-search/smart-search.effects';
import { signUpSmartSearchReducer } from '@shared/store/sign-up/smart-search/smart-search.reducer';
import { SignUpUserEffects } from '@shared/store/sign-up/user/user.effects';
import { signUpUserReducer } from '@shared/store/sign-up/user/user.reducer';

export const signUpEffects = [
  SignUpBrandingEffects,
  SignUpFlowEffects,
  SignUpPropertyEffects,
  SignUpMortgageEffects,
  SignUpSmartSearchEffects,
  SignUpUserEffects,
];

export const signUpReducers = combineReducers({
  branding: signUpBrandingReducer,
  flow: signUpFlowReducer,
  property: signUpPropertyReducer,
  mortgage: signUpMortgageReducer,
  smartSearch: signUpSmartSearchReducer,
  user: signUpUserReducer,
});
