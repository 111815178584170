import { createReducer, on } from '@ngrx/store';
import {
  getSignUpSmartSearchMortgageProducts,
  getSignUpSmartSearchMortgageProductsFailure,
  getSignUpSmartSearchMortgageProductsSuccess,
  loadSignUpSmartSearchFromStorageSuccess,
  setSignUpSmartSearchForm,
} from '@shared/store/sign-up/smart-search/smart-search.actions';
import { SignUpSmartSearchState } from './smart-search.state';

export const signUpSmartSearchInitialState: SignUpSmartSearchState = {
  entity: null,
  liveResultsProducts: [],
  numberOfProducts: 0,
  loading: true,
  errors: null,
};

export const signUpSmartSearchReducer = createReducer(
  signUpSmartSearchInitialState,
  on(loadSignUpSmartSearchFromStorageSuccess, (_, { state }): SignUpSmartSearchState => ({ ...(state ?? signUpSmartSearchInitialState) })),
  on(
    setSignUpSmartSearchForm,
    (state, formData): SignUpSmartSearchState => ({
      ...state,
      entity: {
        buyingProcessStage: formData.buyingProcessStage,
        financingScheme: formData.financingScheme,
        basicDetails: formData.basicDetails,
        initialRate: formData.initialRate,
        liveResults: formData.liveResults,
      },
      loading: false,
    }),
  ),
  on(getSignUpSmartSearchMortgageProducts, (state) => ({
    ...state,
    loading: true,
  })),
  on(getSignUpSmartSearchMortgageProductsSuccess, (state, { products, numberOfProducts }) => ({
    ...state,
    liveResultsProducts: products ?? [],
    numberOfProducts,
    loading: false,
  })),
  on(getSignUpSmartSearchMortgageProductsFailure, (state) => ({
    ...state,
    loading: false,
  })),
);
