import { MortgageFormValues } from '@app/sign-up/utils/mortgage-form';
import { createSelector } from '@ngrx/store';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';

export const selectSignUpMortgageState = createSelector(selectSignUpState, (state) => state.mortgage);

export const selectSignUpMortgageData = createSelector(
  selectSignUpMortgageState,
  (mortgageState): MortgageFormValues | null => mortgageState.entity,
);

export const selectSignUpMortgageIsLoading = createSelector(selectSignUpMortgageState, (mortgageState): boolean => mortgageState.loading);
