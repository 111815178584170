import { MortgageFormValues } from '@app/sign-up/utils/mortgage-form';
import { ErrorModel } from '@shared/models/app-state.model';

export interface SignUpMortgageState {
  entity: MortgageFormValues | null;
  loading: boolean;
  errors: ErrorModel | null;
}

export const signUpMortgageSelectorName = 'signUpMortgage';
