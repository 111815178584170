import { HTTP_X_CompareMortgageProducts_Response_Product } from '@api-new/mortgageservice';
import { SmartSearchFormValues } from '@app/sign-up/utils/smart-search-form';
import { createSelector } from '@ngrx/store';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';

export const selectSignUpSmartSearchState = createSelector(selectSignUpState, (state) => state.smartSearch);

export const selectSignUpSmartSearchData = createSelector(
  selectSignUpSmartSearchState,
  (smartSearchState): SmartSearchFormValues | null => smartSearchState.entity,
);

export const selectSignUpSmartSearchIsLoading = createSelector(
  selectSignUpSmartSearchState,
  (smartSearchState): boolean => smartSearchState.loading,
);

export const selectSignUpSmartLiveResultsProducts = createSelector(
  selectSignUpSmartSearchState,
  (smartSearchState): HTTP_X_CompareMortgageProducts_Response_Product[] => smartSearchState.liveResultsProducts,
);

export const selectSignUpSmartLiveResultsNumberOfProducts = createSelector(
  selectSignUpSmartSearchState,
  (smartSearchState): number => smartSearchState.numberOfProducts,
);
