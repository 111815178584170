import { createAction, props } from '@ngrx/store';
import { EntryFormValuesModel, SignUpFlowState } from '@shared/store/sign-up/flow/flow.state';

export const loadSignUpFlowFromStorage = createAction('[SIGN UP] Load Flow From Storage');
export const loadSignUpFlowFromStorageSuccess = createAction(
  '[SIGN UP] Load Flow From Storage Success',
  props<{ state: SignUpFlowState | null }>(),
);

export const setSignUpFlowData = createAction('[SIGN UP] Set Flow Data', props<EntryFormValuesModel>());

export const setSignUpFlowInitialStep = createAction('[SIGN UP] Set Initial Step', props<{ step: number }>());

export const setSignUpFlowCurrentStep = createAction('[SIGN UP] Set Current Step', props<{ step: number }>());

export const signUpFlowPreviousStep = createAction('[SIGN UP] Previous Step');
export const signUpFlowNextStep = createAction('[SIGN UP] Next Step');
