import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { AppState, ErrorModel } from '@shared/models/app-state.model';
import { LocalStorageSyncService } from '@shared/services/local-storage-sync.service';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';
import { signUpStateToHttpRequestAdapter } from '@shared/store/sign-up/user/adapters/signUpStateToHttpRequestAdapter';
import { selectSignUpUserData, selectSignUpUserState } from '@shared/store/sign-up/user/user.selectors';
import { signUpUserSelectorName } from '@shared/store/sign-up/user/user.state';
import { EMPTY, tap, withLatestFrom } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  createSignUpUser,
  createSignUpUserFailure,
  createSignUpUserSuccess,
  loadSignUpUserFromStorage,
  loadSignUpUserFromStorageSuccess,
  saveSignUpUserToStorage,
} from './user.actions';

@Injectable()
export class SignUpUserEffects {
  private readonly actions$ = inject(Actions);
  private readonly store: Store<AppState> = inject(Store<AppState>);
  private readonly localStorageSyncService = inject(LocalStorageSyncService);
  private readonly authApiService = inject(AuthApiService);
  private readonly toastService = inject(ToastService);
  private readonly router = inject(Router);

  loadUserFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSignUpUserFromStorage),
      map(() => loadSignUpUserFromStorageSuccess({ state: this.localStorageSyncService.loadFromStorage(signUpUserSelectorName) })),
    ),
  );

  saveUserToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSignUpUserToStorage),
        withLatestFrom(this.store.select(selectSignUpUserState)),
        tap(([_, userState]) => {
          this.localStorageSyncService.saveToStorage(signUpUserSelectorName, userState);
        }),
      ),
    { dispatch: false },
  );

  createSignUpUser = createEffect(() =>
    this.actions$.pipe(
      ofType(createSignUpUser),
      withLatestFrom(this.store.select(selectSignUpState)),
      switchMap(([_, signUpState]) => {
        const requestPayload = signUpStateToHttpRequestAdapter(signUpState);
        if (requestPayload == null) return EMPTY;

        return this.authApiService.HTTP_CP_SignUp(requestPayload).pipe(
          map(() => createSignUpUserSuccess()),
          catchError((error: ErrorModel) => {
            this.toastService.showToast(ToastSeverity.error, 'Something went wrong while creating your account.');
            return of(createSignUpUserFailure({ error }));
          }),
        );
      }),
    ),
  );

  createSignUpUserSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSignUpUserSuccess),
        withLatestFrom(this.store.select(selectSignUpUserData)),
        tap(([_, userData]) => {
          localStorage.clear();
          this.router.navigate([paths.SIGN_UP, paths.signUp.WelcomeEmail], { queryParams: { email: userData?.email } });
        }),
      ),
    { dispatch: false },
  );
}
