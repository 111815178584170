import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '@shared/models/app-state.model';
import { AdviserEffects } from '@shared/store/adviser/adviser.effects';
import { adviserReducer } from '@shared/store/adviser/adviser.reducer';
import { AuthEffect } from '@shared/store/auth/auth.effect';
import { authReducer } from '@shared/store/auth/auth.reducer';
import { bestBetterDealsReducer } from '@shared/store/best-better-deals/best-better-deals.reducer';
import { BetterDealEffects } from '@shared/store/better-deal/better-deal.effects';
import { betterDealsReducer } from '@shared/store/better-deal/better-deal.reducer';
import { ClientUserEffects } from '@shared/store/client-user/client-user.effects';
import { clientUserReducer } from '@shared/store/client-user/client-user.reducer';
import { FullstoryEffect } from '@shared/store/client-user/fullstory.effect';
import { CommonEffects } from '@shared/store/common.effects';
import { CompanyEffects } from '@shared/store/company/company.effects';
import { companyReducer } from '@shared/store/company/company.reducer';
import { HelpCasesEffects } from '@shared/store/help-cases/help-cases.effects';
import { helpCasesReducer } from '@shared/store/help-cases/help-cases.reducer';
import { LendersEffects } from '@shared/store/lender/lenders.effects';
import { lendersReducer } from '@shared/store/lender/lenders.reducer';
import { MortgageCasesEffects } from '@shared/store/mortgage-cases/mortgage-cases.effects';
import { mortgageCasesReducer } from '@shared/store/mortgage-cases/mortgage-cases.reducer';
import { MortgagesEffects } from '@shared/store/mortgages/mortgages.effects';
import { mortgagesReducer } from '@shared/store/mortgages/mortgages.reducer';
import { PropertiesEffects } from '@shared/store/properties/properties.effects';
import { propertiesReducer } from '@shared/store/properties/properties.reducer';
import { signUpEffects, signUpReducers } from '@shared/store/sign-up';
import { SmartSearchCasesEffects } from '@shared/store/smart-search-cases/smart-search-cases.effects';
import { smartSearchCasesReducer } from '@shared/store/smart-search-cases/smart-search-cases.reducer';
import { SmartSearchDealsEffects } from '@shared/store/smart-search-deals/smart-search-deals.effects';
import { smartSearchDealsReducer } from '@shared/store/smart-search-deals/smart-search-deals.reducer';
import { SmartSearchesEffects } from '@shared/store/smart-searches/smart-searches.effects';
import { smartSearchesReducer } from '@shared/store/smart-searches/smart-searches.reducer';

export const effects = [
  AuthEffect,
  CommonEffects,
  AdviserEffects,
  BetterDealEffects,
  LendersEffects,
  MortgageCasesEffects,
  MortgagesEffects,
  PropertiesEffects,
  SmartSearchCasesEffects,
  SmartSearchDealsEffects,
  SmartSearchesEffects,
  ClientUserEffects,
  HelpCasesEffects,
  FullstoryEffect,
  CompanyEffects,
  ...signUpEffects,
];

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  adviser: adviserReducer,
  bestBetterDeals: bestBetterDealsReducer,
  betterDeals: betterDealsReducer,
  lenders: lendersReducer,
  mortgageCases: mortgageCasesReducer,
  mortgages: mortgagesReducer,
  properties: propertiesReducer,
  smartSearchCases: smartSearchCasesReducer,
  smartSearchDeals: smartSearchDealsReducer,
  smartSearches: smartSearchesReducer,
  clientUser: clientUserReducer,
  helpCases: helpCasesReducer,
  company: companyReducer,
  signUp: signUpReducers,
};
