import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { ENTRY_FLOW } from '@app/sign-up/constants/entry-flow';
import { FIRST_TIME_BUYER_FLOW } from '@app/sign-up/constants/first-time-buyer-flow';
import { MORTGAGE_MONITOR_FLOW } from '@app/sign-up/constants/mortgage-monitor-flow';
import { SignUpState } from '@app/sign-up/models/sign-up-state.model';
import { EntryLandingEnum } from '@app/sign-up/pages/entry/components/entry-landing/enums/entry-landing.enum';

export function getPreviousStep(appState: SignUpState): number {
  const currentStep = appState.flow.currentStep;

  if (currentStep === ENTRY_FLOW.MortgageSwitchUrgency) {
    return ENTRY_FLOW.Landing;
  }

  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER) {
    return handleFirstTimeBuyerFlow(appState);
  }

  // Mortgage Monitor Flow
  if (appState.flow.entity.creationMethod === HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR) {
    if (currentStep === MORTGAGE_MONITOR_FLOW.PropertyAddress) {
      if (appState.flow.entity.landing === EntryLandingEnum.TrackMortgage) {
        return ENTRY_FLOW.Landing;
      }

      return ENTRY_FLOW.MortgageSwitchUrgency;
    }
  }

  return currentStep - 1;
}

const handleFirstTimeBuyerFlow = (appState: SignUpState): number => {
  const currentStep = appState.flow.currentStep;

  if (currentStep === FIRST_TIME_BUYER_FLOW.SmartSearchBuyingProcessStage) {
    if (appState.flow.entity.landing === EntryLandingEnum.BuyProperty) {
      return ENTRY_FLOW.BuyerType;
    }
  }

  return appState.flow.currentStep - 1;
};
