import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { createSelector } from '@ngrx/store';
import { selectSignUpState } from '@shared/store/sign-up/sign-up.selectors';

export const selectSignUpPropertyState = createSelector(selectSignUpState, (state) => state.property);

export const selectSignUpPropertyData = createSelector(
  selectSignUpPropertyState,
  (propertyState): PropertyFormValues | null => propertyState.entity,
);

export const selectSignUpPropertyIsLoading = createSelector(selectSignUpPropertyState, (propertyState): boolean => propertyState.loading);
