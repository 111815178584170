import {
  HTTP_CP_SignUp_Request,
  HTTP_CP_SignUp_Request_ClientUser,
  HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod,
  HTTP_CP_SignUp_Request_Mortgage,
  HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData,
  HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct,
  HTTP_CP_SignUp_Request_Property,
  HTTP_CP_SignUp_Request_Property_AdditionalPropertyData,
  HTTP_CP_SignUp_Request_Property_PropertyValue,
} from '@api-new/authservice';
import { DATA_Address } from '@api-new/common';
import { SignUpState } from '@app/sign-up/models/sign-up-state.model';
import { MortgageFormValues } from '@app/sign-up/utils/mortgage-form';
import { PropertyFormValues } from '@app/sign-up/utils/property-form';
import { CreateUserFormValues } from '@client-ui/components/forms/create-user-form/utils/form-utils';
import { SKIPPED_PRODUCT_MATCH_PLACEHOLDER } from '@client-ui/components/forms/mortgage-product-match-form/mortgage-product-match-form.component';
import dayjs from 'dayjs';

export function signUpStateToHttpRequestAdapter(data: SignUpState): HTTP_CP_SignUp_Request | undefined {
  const clientData = data.user.entity;
  const creationMethod = data.flow.entity.creationMethod;
  const companyId = data.branding.entity?.companyId;

  if (clientData == null || creationMethod == null || companyId == null) {
    return undefined;
  }

  switch (creationMethod) {
    case HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod.SIGN_UP_CREATION_METHOD_LANDLORD: {
      return {
        clientUser: getClientUser(clientData, data.branding.referralCode),
        landlord: {
          declaredNumberOfProperties: clientData.numberOfProperties!,
        },
      };
    }
    default: {
      return undefined;
    }
  }
}

function getClientUser(clientData: CreateUserFormValues, referralCode: string | null): HTTP_CP_SignUp_Request_ClientUser {
  return {
    email: clientData.email,
    phoneNumber: clientData.phoneNumber,
    firstName: clientData.firstName,
    middleName: clientData.middleName.length > 0 ? clientData.middleName : undefined,
    lastName: clientData.lastName,
    dateOfBirth: clientData.dateOfBirth?.toISOString() ?? undefined,
    referralCode: referralCode ?? undefined,
  };
}

function getSignUpPropertyData(property: PropertyFormValues | null): HTTP_CP_SignUp_Request_Property | undefined {
  if (property == null) return undefined;

  const propertyManualAddress: DATA_Address | undefined = property?.address.isManual
    ? {
        postcode: property.address.postcode ?? '',
        buildingNumber: property.address.buildingNumber ?? '',
        buildingName: property.address.buildingName ?? '',
        subBuildingName: '',
        companyName: property.address.companyName ?? '',
        street: property.address.street ?? '',
        dependentStreet: '',
        city: property.address.city ?? '',
      }
    : undefined;

  const addressId: string | undefined =
    typeof property?.address.pickedAddress != 'string' ? property!.address.pickedAddress?.addressId : undefined;

  const propertyValue: HTTP_CP_SignUp_Request_Property_PropertyValue | undefined = property
    ? {
        year: property.propertyValuation.date.getFullYear(),
        month: property.propertyValuation.date.getMonth() + 1,
        manualValue: property.propertyValuation.valuation,
        manualPropertyValueType: property.propertyValuation.valuationSource,
      }
    : undefined;

  const additionalPropertyData: HTTP_CP_SignUp_Request_Property_AdditionalPropertyData | undefined = property.addressDetails
    ? {
        propertyType: property.addressDetails.propertyType ?? undefined,
        constructionYear: property.addressDetails.constructionYear ?? undefined,
        numberOfBedrooms: property.addressDetails.numberOfBedrooms ?? undefined,
        ownershipShare: property.additionalInfo.ownershipShare ?? undefined,
        tenure: property.addressDetails.tenure ?? undefined,
      }
    : undefined;

  return {
    addressId,
    address: propertyManualAddress,
    investmentType: property.investmentType,
    propertyValue,
    additionalPropertyData,
  };
}

function getSignUpMortgageData(mortgage: MortgageFormValues | null, companyId: string): HTTP_CP_SignUp_Request_Mortgage | undefined {
  if (mortgage == null) return undefined;

  return {
    writtenByCompanyId: companyId,
    lenderId: mortgage.lenderAndStartDate.lenderId,
    mortgageParts: mortgage.mortgageParts.parts.map((part, index) => {
      const mortgagePartFromMortgageProduct: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct | undefined =
        mortgage.productMatch.partsMortgageProductIds[index] !== SKIPPED_PRODUCT_MATCH_PLACEHOLDER
          ? {
              mortgageProductId: mortgage.productMatch.partsMortgageProductIds[index],
              startDate: part.startDate?.toISOString(),
              endDate: dayjs(part.startDate).add(part.term, 'month').toISOString(),
            }
          : undefined;

      const mortgagePartFromData: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData | undefined =
        mortgage.productMatch.partsMortgageProductIds[index] === SKIPPED_PRODUCT_MATCH_PLACEHOLDER
          ? {
              initialMortgagePartStepType: part.initialRateType,
              initialPeriodEndDate: dayjs(part.startDate).add(part.initialRatePeriod, 'month').toISOString(),
              startDate: part.startDate?.toISOString(),
              endDate: dayjs(part.startDate).add(part.term, 'month').toISOString(),
              initialInterestRate: part.initialInterestRate,
            }
          : undefined;

      return {
        initialAmount: part.borrowedAmount,
        repaymentType: part.repaymentType,
        mortgagePartFromMortgageProduct,
        mortgagePartFromData,
      };
    }),
  };
}
