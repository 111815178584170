import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FeatureFlag } from '../models/feature-config.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  isFeatureEnabled(key: FeatureFlag): boolean {
    if (environment.featureConfig && environment.featureConfig[key]) {
      return Boolean(environment.featureConfig[key]);
    }
    return false;
  }
}
