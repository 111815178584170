import { createReducer, on } from '@ngrx/store';
import { loadSignUpPropertyFromStorageSuccess, saveSignUpPropertyToStorage } from '@shared/store/sign-up/property/property.actions';
import { SignUpPropertyState } from './property.state';

export const signUpPropertyInitialState: SignUpPropertyState = {
  entity: null,
  loading: false,
  errors: null,
};

export const signUpPropertyReducer = createReducer(
  signUpPropertyInitialState,
  on(loadSignUpPropertyFromStorageSuccess, (_, { state }) => ({
    ...(state
      ? {
          ...state,
          entity: state.entity
            ? {
                ...state.entity,
                propertyValuation: { ...state.entity.propertyValuation, date: new Date(state.entity.propertyValuation.date) },
              }
            : null,
        }
      : signUpPropertyInitialState),
  })),
  on(saveSignUpPropertyToStorage, (state, formData) => ({
    ...state,
    entity: {
      address: formData.address,
      addressDetails: formData.addressDetails,
      additionalInfo: formData.additionalInfo,
      investmentType: formData.investmentType,
      propertyValuation: formData.propertyValuation,
      residentialAddress: formData.residentialAddress,
    },
    loading: false,
  })),
);
