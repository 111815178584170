import { HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod } from '@api-new/authservice';
import { ENTRY_FLOW_STEPS } from '@app/sign-up/constants/entry-flow';
import { FLOWS } from '@app/sign-up/constants/flows';
import { FlowStepModel, FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { Flow, FlowSteps } from '@shared/store/sign-up/flow/flow.state';

export function getFlowSteps(creationMethod: HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod | null): FlowSteps | undefined {
  return creationMethod != null ? FLOWS[creationMethod]?.steps : ENTRY_FLOW_STEPS;
}

export function getFlowStep(
  creationMethod: HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod | null,
  stepNumber: number,
): FlowStepModel<Flow> | undefined {
  const selectedFlowSteps = stepNumber >= 0 ? getFlowSteps(creationMethod) : ENTRY_FLOW_STEPS;
  if (selectedFlowSteps == null) return undefined;

  return (selectedFlowSteps as FlowStepsModel<Flow>)[stepNumber];
}
