import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { FormFieldWrapperComponent } from '@shared-lib/components/form-components/form-field-wrapper/form-field-wrapper.component';
import { InputTextComponent } from '@shared-lib/components/form-components/input-text/input-text.component';
import { ButtonDirective } from 'primeng/button';
import { take } from 'rxjs/operators';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-login-email-input',
  templateUrl: './login-email-input.component.html',
  styleUrls: ['../../auth-theme.scss', 'login-email-input.component.scss'],
  imports: [AsyncPipe, InputTextComponent, ReactiveFormsModule, FormFieldWrapperComponent, ButtonDirective, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailInputComponent implements OnInit {
  private readonly facade = inject(AuthFacadeService);
  private readonly destroyRef = inject(DestroyRef);

  protected readonly loading$ = this.facade.loading$;
  protected readonly email$ = this.facade.email$;
  protected readonly noEmailFoundError$ = this.facade.noEmailFoundError$;

  protected signUpUrl = environment.signUpUrl;
  protected loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  ngOnInit(): void {
    this.noEmailFoundError$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ noEmailFound }) => {
      if (noEmailFound) {
        this.loginForm.controls.email.setErrors({ message: 'There is no user account with this e-mail address' });
      }
    });

    this.email$.pipe(take(1)).subscribe((email) => {
      const lastCheckedEmail = localStorage.getItem('LAST_CHECKED_EMAIL') || '';
      this.loginForm.setValue({ email: email || lastCheckedEmail });
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.facade.checkLoginMail(this.loginForm.value.email);
  }
}
